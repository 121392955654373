<template>
  <Header />
  <div class="container mb-400 mt-150 box-shadow border-radius">
    <div class="row border-radius ">
      <div class="col-md-4 bg-yellow o-2">
        <div class="img-circular"></div>
        <h5 class="text-center clr-black">Aliret - Webcloner Blog</h5>
        <h6 class="text-center clr-black fw-400">Useless blog</h6>
        <div class="social-links">
          <p class="text-center social-nav">
            <span><a target="_blank" href="https://www.facebook.com/aliretServices/"><i class="fa fa-facebook"></i></a></span>
            <span><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></span>
            <span><a target="_blank" href="https://www.instagram.com/aliretservice/"><i class="fa fa-instagram"></i></a></span>
            <span><a target="_blank" href="https://www.linkedin.com/company/aliret-services"><i class="fa fa-linkedin"></i></a></span>
            <span><a target="_blank" href="#"><i class="fa fa-youtube"></i></a></span>
          </p>
        </div>
      </div>
      <div class="col-md-8 bg-white ">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-10">
              <div class="mt-3">
                <h1 class="h1 text-center"><span class="clr-black">Our</span> <span class="clr-yellow">Blog</span></h1>
                <div class="article-line article-line-black"></div>
              </div>
              <div class="container">
                <div class="row">
                  <div v-for="(blog, index) in blogs" :key="index" class="col-6" style="box-shadow: 2px 2px 2px 5px grey; padding: 20px;">
                    <router-link :to="'/blog/'+blog.title">{{blog.title}}
                    <img :src="'/blogs/'+blog.image" style="width: 100%;"></router-link>
                  </div>
                </div>
              </div>
              <p class="mt-3 fw-400">Thank you for reading this!</p>
              <footer class="blockquote-footer mb-5">Webcloner
                <cite title="Source Title">Aliret Service</cite>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { db, firebaseInstance } from "../firebase";
import Header from './../components/Header.vue'
import Footer from './../components/Footer.vue'
export default {
  name: 'Blog',
  components: {
    Header,
    Footer
  },
  mounted(){
    firebaseInstance.auth().signInAnonymously();
  },
  data() {
    return {
      blogs: []
    }
  },
  created() {
    db.collection("blogs").where("title", "!=", "").get().then((snapshot) => {
      snapshot.forEach(doc => {
        this.blogs.push(doc.data());
      })
    });
  },
} 
</script>
