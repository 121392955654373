import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';

const fireBaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBr2y5t5CT5pFwDTiDYQev4C2vFSGKCrwI",
  authDomain: "webcloner-93632.firebaseapp.com",
  databaseURL: "https://webcloner-93632.firebaseio.com",
  projectId: "webcloner-93632",
  storageBucket: "webcloner-93632.appspot.com",
  messagingSenderId: "743395707224",
  appId: "1:743395707224:web:b0a82c663acdec9b357d2d",
  measurementId: "G-XRZYD1KTF8"
})
  
export const db = fireBaseApp.firestore()
export const firebaseInstance = firebase