import { createRouter, createWebHistory } from 'vue-router'
import Welcome from '../views/Welcome.vue'
import Blog from '../views/Blog.vue'
import BlogDetail from '../views/BlogDetail.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
// import Contact from '../views/Contact.vue'
import Hire from '../views/Hire.vue'

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/blog/:title',
    name: 'Blog-details',
    component: BlogDetail
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  // {
  //   path: '/contact-us',
  //   name: 'Contact',
  //   component: Contact
  // },
  {
    path: '/hire-us',
    name: 'Hire',
    component: Hire
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
