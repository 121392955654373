<template>
  <Header />
  <div class="container mb-400 mt-200 box-shadow border-radius">
    <div class="row border-radius ">
      <div class="col-md-4 bg-black o-2">
        <div class="img-circular"></div>
        <h5 class="text-center clr-white">Aliret Service</h5>
        <div class="social-links">
          <p class="text-center footer-nav main-footer">
            <span class="mr-2"><a target="_blank" href="https://www.facebook.com/aliretServices/"><i class="fa fa-facebook"></i></a></span>
            <span class="mr-2"><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></span>
            <span class="mr-2"><a target="_blank" href="https://www.instagram.com/aliretservice/"><i class="fa fa-instagram"></i></a></span>
            <span class="mr-2"><a target="_blank" href="https://www.linkedin.com/company/aliret-services"><i class="fa fa-linkedin"></i></a></span>
            <span class="mr-2"><a target="_blank" href="#"><i class="fa fa-youtube"></i></a></span>

          </p>
        </div>
        <h3 class="clr-white text-center">An IT services company that helps other businesses succeed</h3>
        <div class="article-line center mb-3 mt-3"></div>
        <p class="text-center clr-white">
          We specialize in the design and development of IT systems, have a panel of experienced profiles in various areas of expertise: new technologies, gaming, system infrastructure, networks, security, DevOps, Cloud, BI, BIG DATA, ML.
        </p>
      </div>
      <div class="col-md-8 bg-white ">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-10">
              <div class="mt-3">
                <h1 class="h1 text-center clr-yellow">Hire <span class="clr-black">me</span></h1>
                <div class="article-line article-line-black"></div>
              </div>
              <form id="contactForm" class="">
                <div class="form-group">
                  <label>Your Name</label>
                  <input type="text" id="name" class="form-control df-input"></div>
                <div class="form-group">
                  <label>Your Email Address</label>
                  <input type="text" id="email" class="form-control df-input"></div>
                <div class="form-group">
                  <label>Project Title</label>
                  <input type="text" id="project_title" class="form-control df-input"></div>
                <div class="form-group">
                  <label>Budget (in USD)</label>
                  <input type="text" id="project_budget" class="form-control df-input"></div>
                <div class="form-group">
                  <label>Breif About Project</label>
                  <textarea rows="8" id="project_breif" class="form-control df-input"></textarea></div>
                <div class="form-group pb-50 mb-5">
                  <span id="showMsg" class="float-left"></span>
                  <button type="button" id="project-send" class="btn btn-yellow float-right">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from './../components/Header.vue'
import Footer from './../components/Footer.vue'
export default {
  name: 'Contact',
  components: {
    Header,
    Footer
  },
} 
</script>
