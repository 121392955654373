<template>
  <!-- Page Footer-->
  <footer class="main-footer mt-100">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-12">
          <p class="text-center footer-nav">
            <span><router-link to="/privacy-policy">Privacy Policy</router-link></span>
          </p>
          <p class="text-center footer-brand">
            <img src="@/assets/logo.png" class="footer-logo">
          </p>
          <p class="text-center footer-nav">
            <span><a target="_blank" href="https://www.facebook.com/aliretServices/"><i class="fa fa-facebook"></i></a></span>
            <!-- <span><a target="_blank" href="#"><i class="fa fa-twitter"></i></a></span> -->
            <span><a target="_blank" href="https://www.instagram.com/aliretservice/"><i class="fa fa-instagram"></i></a></span>
            <span><a target="_blank" href="https://www.linkedin.com/company/aliret-services/"><i class="fa fa-linkedin"></i></a></span>
            <!-- <span><a target="_blank" href="#"><i class="fa fa-youtube"></i></a></span> -->
          </p>
          <p class="text-center clr-white tagline">
            <span>Aliret Service, Tunisia</span>
          </p>
          <p class="copyright text-center">© 2024 <strong><a href="https://aliret.com/">Aliret</a></strong>. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .footer-logo {
    background: white;
    border-radius: 50%;
  }
</style>
