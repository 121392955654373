<template>
  <header class="header">
    <!-- Main Navbar-->
    <nav class="navbar navbar-expand-lg  header-shadow">
      <div class="search-area">
        <div class="search-area-inner d-flex align-items-center justify-content-center">
          <div class="close-btn"><i class="icon-close"></i></div>
          <div class="row d-flex justify-content-center">
            <div class="col-md-8">
              <form action="#">
                <div class="form-group">
                  <input type="search" name="search" id="search" placeholder="What are you looking for?"><button
                    type="submit" class="submit"><i class="icon-search-1"></i></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <!-- Navbar Brand -->
        <div class="navbar-header d-flex align-items-center justify-content-between">
          <!-- Navbar Brand -->
          <router-link to="/" class="navbar-brand brand-text">
            <img src="@/assets/logo.png" class="header-logo">
            <span class="clr-black">Web</span><span class="clr-yellow">Cloner</span>
          </router-link>
          <!-- Toggle Button-->
          <button type="button" data-toggle="collapse" data-target="#navbarcollapse" aria-controls="navbarcollapse"
            aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewbox="0 0 24 24" class="c-MenuIcon">
              <path stroke="#2C2C2C" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12h18M9 6h12M3 18h12"></path>
            </svg>
            <svg style="display: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewbox="0 0 24 24" class="c-CloseIcon">
              <g><path fill="#2C2C2C" d="M18.69 5.321a1.058 1.058 0 00-1.498 0L12 10.503 6.808 5.31A1.057 1.057 0 105.31 6.808L10.503 12 5.31 17.192a1.057 1.057 0 101.497 1.497L12 13.497l5.192 5.192a1.057 1.057 0 101.497-1.497L13.497 12l5.192-5.192a1.064 1.064 0 000-1.487z"></path></g>
            </svg>
          </button>
        </div>
        <!-- Navbar Menu -->
        <div id="navbarcollapse" class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item"><router-link to="/" class="nav-link ">home</router-link></li>
            <li class="nav-item "><router-link to="/blog" class="nav-link ">Our Blog</router-link></li>
            <li class="nav-item "><router-link to="/privacy-policy" class="nav-link ">Privacy Policy</router-link></li>
            <li class="nav-item"><a href="https://wa.me/21653040019" class="nav-link ">Contact Us</a></li>
          </ul>
        </div>
        <div id="navbarcollapse" class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto">
            <li>
              <router-link to="/hire-us"><button type="button" class="btn btn-black">HIRE US</button></router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .header-logo {
    width: 45px;
    padding-right: 5px;
  }
</style>
