<template>
  <router-view/>
</template>

<style>
@import './assets/css/css-bootstrap.min.css';
@import './assets/css/css-font-awesome.min.css';
@import './assets/css/css-style-default.css';
@import './assets/css/css-custom.min.css';
</style>
